import { Component , Input, EventEmitter, Output} from '@angular/core';
import { MouseEvent } from '@agm/core';
import {Http, Response} from '@angular/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';
import { CookieService } from 'ngx-cookie-service';
import 'rxjs/add/operator/debounceTime';
import { CategoryQuestionsData } from './CategoryQuestionsData';
import { QuestionData } from './QuestionData';
import { MatDialog } from '@angular/material';
import {MyAlertDialogComponent} from 'src/app/my-alert-dialog/my-alert-dialog.component';
import {VedioComponent} from 'src/app/vedio/vedio.component';
import {ErrorComponent} from 'src/app/error/error.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})

export class MapComponent {
  // Rating
  max = 5;
  rate = 0;
  isReadonly = false;
  overStar: number;
  percent: number;
  appliancePercentResult = 100;

  // Slider Config 
  config: Object = {
    simulateTouch: false,
    autoHeight: true, 
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    spaceBetween: 30,
  };
  
   // Only Numbers
    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;    
    }
 
   //Map API
  constructor(private http: Http, private modalService: BsModalService, private cookieService: CookieService,private dialog: MatDialog){}

  // google maps zoom level
  zoom: number = 10;
  // initial center position for the map
  lat: number = 31.5464762;
  lng: number =  120.2796121;
  code:number;
  address:string;
  center:marker;
  polygon:marker[];
  ShowAddresss;
  searchCode(){
    //  console.log(this.code)
    this.CallGeoAPI().subscribe(res=>{
    //  console.log(res);
      const result = res[0];
      this.address = result.formatted_address;
      this.center = result.geometry.location;
      this.lat = this.center.lat;
      this.lng = this.center.lng;
  
      this.ShowAddresss = [];
      this.ShowAddresss.push(this.address);

      this.polygon = [
        {
          lat: result.geometry.bounds.northeast.lat,
          lng: result.geometry.bounds.northeast.lng
        },
        {
          lat: result.geometry.bounds.northeast.lat,
          lng: result.geometry.bounds.southwest.lng
        },
        {
          lat: result.geometry.bounds.southwest.lat,
          lng: result.geometry.bounds.southwest.lng
        },
        {
          lat: result.geometry.bounds.southwest.lat,
          lng: result.geometry.bounds.northeast.lng
        },
      ]
    })  
  }
  // Value in Postal Code
  CallGeoAPI(){
    let apiURL = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.code}&key=AIzaSyAgDUII_kvGfCJNmu4qhhzjl8YNzblV9Ng`;
    return this.http.get(apiURL).map((response: Response)=>{
      return response.json().results;
    })
  }
   
  // Modal in Question
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef4: BsModalRef;
  modalRef3: BsModalRef;
  openModal(template) {
      setTimeout(() => {
        this.modalRef2 = this.modalService.show(template, { class: 'other modal-lg' , backdrop:'static'});
      }, 1000);
  }
  ScreeImage(template) {
    this.modalRef3 = this.modalService.show(template, { class: 'modal-lg'});
  }
  TermsCon(template) {
     this.modalRef4 = this.modalService.show(template, { class: 'modal-lg'});
  }

  closeFirstModal() {
    this.modalRef.hide(); 
    this.modalRef = null;
  }

  // Calcualtion Start
  // variable Declare
  ShowCityInPostal:string;
  ShowWinterConstant : number;
  ShowSummerConstant: number;
  ShowCityInPostals;
  ShowWinterConstantValue1;
  ShowSummerConstantValue1;
  HeatCostConstantValue;
  CoolCostConstantValue;
  HVACdata:string;
  HVACdata_s:string;
  filteroptions:string;
  categories1:string;
  HVACdatafilter;
  optionfilter;
  optionfilters;
  nextoptionfilter;
  ShowPostalCode;
  ShowPostalCodes;
  nextoptionfilters;
  Pstalcodes;
  Cookies = true;
  IsNatural = true;
  DisabledAread = true;
  showError;
  Living;postal;
  IsNaturals = true;
  // Using Postal code Get City
  GetCity(postalcode: any) { 
    this.Pstalcodes = postalcode;
  this.PostalCode =  postalcode.trim().toUpperCase().substring(0,3);
  this.http.get("https://logo.sdssoftltd.co.uk/index.php/calculates/getPostal?postal_code=" + this.PostalCode)
  .map((response) => response.json() )
  .subscribe((data) => this.ShowCity(data))   
 }
  // Show City
  ShowCity(data) { this.ShowCityInPostal = data;
    this.ShowCityInPostals = [];
    this.ShowPostalCodes = [];
    for(var i = 0; i<data.length; i++){
      var ShowCityInPostal = data[0]['city'];
      this.ShowPostalCode = data[0]['postal_code'];
      this.ShowCityInPostals.push(ShowCityInPostal);
      this.ShowPostalCodes.push(this.ShowPostalCode);
    }
    // Using City Get Winter Constant 
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/calculates/getConstants?city=" + this.ShowCityInPostals )
    .map((response) => response.json() )
    .subscribe((constant) => this.ShowWinterValue(constant))
  }
    
  questionvalue(template) {
    setTimeout(() => {
      this.modalRef = this.modalService.show(template, { class: '' , backdrop:'static'});
    }, 2000);
  }
    //Cookies Create
  Cookiess():void{
    var requestMessage = {
    "cat_ques_id" : this.checkedvaluesid,
    "cust_input" : this.checkedvalues,
    "cat_ques_option" : this.checkedvalueInput,
    "post_code" : this.postal,
    "living_area" : this.Living,
    }
    if(this.Cookies){
    this.http.post("https://logo.sdssoftltd.co.uk/index.php/categories/postInput?post_code="+this.PostalCode+"&living_area="+this.livingValue+"&cat_ques_id="+ this.checkedvaluesid+"&cat_ques_option="+this.checkedvalueInput+"&cust_input="+this.checkedvalues,"")
      .map((response) => response.json())
      .subscribe();
    this.Cookies = false;
    }
  
    // second Function
    if(this.ShowPostalCodes != this.PostalCode ){
      this.DisabledAread = false; 
      setTimeout(() => {
        let dialogRef = this.dialog.open(MyAlertDialogComponent, { disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
        if (result == 'confirm') {
            console.log('Unregistered');
        }})
      }, 500);
    }
  }
  // Show Winter and Summer Constant
  ShowWinterValue(constant) {this.ShowWinterConstant = constant;
    this.ShowWinterConstantValue1 = [];
    this.ShowSummerConstantValue1 = [];
    this.HeatCostConstantValue = [];
    this.CoolCostConstantValue = [];
    for(var i = 0; i<constant.length; i++){
      var ShowWinterConstant = constant[i]['winter_const'];
      var ShowSummerConstant = constant[i]['summer_const'];
      var HcostConstant = constant[i]['heat_cost_const'];
      var CcostConstant = constant[i]['cooling_cost_const'];
      this.ShowWinterConstantValue1.push(ShowWinterConstant);
      this.ShowSummerConstantValue1.push(ShowSummerConstant);
      this.HeatCostConstantValue.push(HcostConstant);
      this.CoolCostConstantValue.push(CcostConstant);
    }
  }

  // Living Area // bldg heat and bldg cool
  LivingArea(livingarea: any) { 
   //console.log('s' + livingarea);
   this.livingValue = livingarea;
   this.BasebldgCool = [];
   this.BasebldgHeat = [];
    var Buildingwinter = (0.9972 * livingarea) + (0.0028 * livingarea * this.ShowWinterConstantValue1);	
    var Buildingsummer = (0.9972 * livingarea) + (0.0028 * livingarea * this.ShowSummerConstantValue1);	
    this.BasebldgCool.push(Buildingwinter);
    this.BasebldgHeat.push(Buildingsummer);
  }
  
  BasebldgCool;
  BasebldgHeat;
  livingValue;
  PostalCodes:string;
  CategoryQuestionsDatas = []; 
  ShowCategoryName;
  insulationdata:string;
  checkedvalues:number[] = [];
  checkedvaluesid:number[] = [];
  checkedvalueInput:number[] = [];
  InsulationResult;
  IsHidden = true;
  IsHiddens = true;
  doorsdata:string;
  checkedvaluesdoor:number[] = [];
  checkedvaluesdoorid:number [] = [];
  checkedvaluesdoorInput:number [] =[];
  SumofDoorscheckbox;
  bdlgheatinglood;
  bdlgcoolinglood;
  GetCosts;
  CategoryDatass;
  VendorsDetails:string;
  comparesValues;
  IRetrofit;
  VentVendors1;
  ThermostatVendors1;
  Replaces;
  SealsVendors1;
  Furnaces;
  conditioners;
  Geothermals;
  PVs;
  collectors;
  Improvements;
  Appliancessss;
  Sealss;
  Thermostats;
  Ventss;
  Sealsss;
  Attics;
  CategoryStaticData;
  TermContents;
  NeedHelps;
  CheckedInputDynamic:number[] = [];
  CheckedIdDynamic:number[] = [];
  CheckedOptionDynamic:number[] = [];
  FuelSourcess;
  FuelSources;
   ngOnInit() { 
    //Postal Code Filter APi
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/calculates/getPostal?postal_code=")
    .map((response) => response.json() )
    .subscribe((data) => this.PostalCode(data))
    
    //Dynamic Categeory Api
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getCategory")
    .map((response) => response.json() )
    .subscribe((categoryDatastatic) => this.CategoryStatic(categoryDatastatic))

    // Dynamic Data
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getCategory1")
    .map((response) => response.json() )
    .subscribe((categoryData) => this.Category(categoryData))

    // Get Question
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getQuestion?cat_id=1")
    .map((response) => response.json() )
    .subscribe((data) => this.displaydata(data))

    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getQuestion?cat_id=2")
    .map((response) => response.json() )
    .subscribe((data) => this.displaydata21(data))

    // Get Select Option Value Api
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getQuestion?cat_id=3")
    .map((response) => response.json() )
    .subscribe((data) => this.displaydataoptionvalue(data))
  
     // Fuel Source option
	  this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getOption?ques_id=6")
    .map((response) => response.json() )
    .subscribe((data) => this.FuelSource(data))

     // All Option Show In Equipment
	  this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getOption?ques_id=7")
    .map((response) => response.json() )
    .subscribe((data) => this.displaydataoptionvaluefilter(data))
	 
    // Other data
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getQuestion?cat_id=4")
    .map((response) => response.json())
    .subscribe((data) => this.displaydataother(data))
   
    // Lighting data
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getOption?ques_id=8")
    .map((response) => response.json())
    .subscribe((data) => this.DisplayDataWater(data))

    // Lighting data
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getOption?ques_id=9")
    .map((response) => response.json())
    .subscribe((data) => this.DisplayDataOption(data))
    
   // Cooling Equipment
   
   this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getOption?ques_id=17")
   .map((response) => response.json())
   .subscribe((data) => this.CoolingData(data))

    // Appliance data
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getOption?ques_id=10")
    .map((response) => response.json())
    .subscribe((data) => this.appliances(data))
   
    //TERMS & CONDITION
     this.http.get("https://logo.sdssoftltd.co.uk/index.php/contents/getContent?title=Terms and Conditions")
     .map((response) => response.json() )
     .subscribe((data) => this.TermContent(data))
    //Compare value
      this.http.get("https://logo.sdssoftltd.co.uk/index.php/contents/getContent?title=Need Help")
      .map((response) => response.json())
      .subscribe((data) => this.NeedHelp(data))

  }

  TermContent(data) {this.TermContents = data } 
  NeedHelp(data) {this.NeedHelps = data } 
  //show insulation Data
  displaydata(data) {this.insulationdata = data;}
  PostalCode(data) {this.PostalCodes = data } 
  displaydata21(data) {this.doorsdata = data;}  
  //checkbox yes 
  checkboxyes(val: any, vals: any, index:number) {   
    // Filter Data In Next Box API
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/Categories/getInput?ques_id="+val+"&option="+vals )
    .map((response) => response.json() )
    .subscribe((data) => this.checkedvalue(data, index))
  } 

   //dynamic Add Categeory
   Category(categoryData) { 
   this.CategoryDatass = categoryData;
   this.ShowCategoryName = [];
   for(var i = 0; i<categoryData.length; i++) {
   var showQuestionId = categoryData[i]['id'];
   var ShowCategory = categoryData[i]['category_name'];
   this.ShowCategoryName.push(ShowCategory)
   this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getQuestion?cat_id=" + showQuestionId)
    .map((response) => response.json() )
    .subscribe((questionsData) => this.getQuestion(categoryData, questionsData))
     }
   }
  getQuestion(categoryData, questionsData) {
    var questions = [];
    var options = [];
    for(var j = 0; j<questionsData.length; j++) {
      var questionData:QuestionData = new QuestionData();
      questionData.setQuestion(questionsData[j]['question']);
      questionData.setQuestionId(questionsData[j]['id']);
      questionData.setQuestionsOptions(questionsData[j]['option'].split(','));
      questions.push(questionData);     
        
  }
  var i = 0;
  var categoryDataObj:CategoryQuestionsData = new CategoryQuestionsData();
  categoryDataObj.setCategoryName(categoryData[i]['category_name']);      
  categoryDataObj.setQuestions(questions);
  this.CategoryQuestionsDatas.push(categoryDataObj);
  }
  // Post Data Value   
  checkedvalue(data, index:number) {
      this.checkedvalues[index] = data[0]['input'];
      this.checkedvaluesid[index] = data[0]['cat_ques_id'];
      this.checkedvalueInput[index] = data[0]['option'];
      var SumofAllcheckbox: number = 0;
      SumofAllcheckbox =  (0.1*this.checkedvalues[0] + 0.1*this.checkedvalues[1] + 0.1*this.checkedvalues[2]);
      this.InsulationResult = SumofAllcheckbox; 
  }
  
  //Door Filter data
  DoorCheckBox(valId: any, value: any, index:number) {
    // Filter Data In Next Box API
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/Categories/getInput?ques_id="+valId+"&option="+value )
    .map((response) => response.json() )
    .subscribe((data) => this.Doorcheckedvalue(data, index))
  }
   
  // Post Data and Filter Data
  Doorcheckedvalue(data, index:number) {
  this.checkedvaluesdoor[index] = data[0]['input'];
  this.checkedvaluesdoorid[index] = data[0]['cat_ques_id'];
  this.checkedvaluesdoorInput[index] = data[0]['option'];
  this.SumofDoorscheckbox = [];
  var SumofDoorscheckbox: number = 0;
  SumofDoorscheckbox =  1 - (this.InsulationResult + 0.1*this.checkedvaluesdoor[0] +  0.14*this.checkedvaluesdoor[1]);
  this.SumofDoorscheckbox.push(SumofDoorscheckbox);
  // ulta ho gya hain heating m cooling and cooling m heating dhyan rakhe
  // Building Cool Load
  this.bdlgheatinglood = [];  
  var bdlgheatinglood = this.BasebldgHeat * this.SumofDoorscheckbox;
  this.bdlgheatinglood.push(bdlgheatinglood);
  // Building Heat Load
  this.bdlgcoolinglood = [];  
  var bdlgcoolinglood = this.BasebldgCool * this.SumofDoorscheckbox;
  this.bdlgcoolinglood.push(bdlgcoolinglood);
  }

  //dynamic category select Data and Post Data   
  DynamicCatData(valId: any, value: any, index:number) {
  // Filter Data In Next Box API
  this.http.get("https://logo.sdssoftltd.co.uk/index.php/Categories/getInput?ques_id="+valId+"&option="+value )
  .map((response) => response.json() )
  .subscribe((data) => this.DoorCheckedDynamic(data, index))
  }

  DoorCheckedDynamic(data, index:number) {
  this.CheckedInputDynamic[index] = data[0]['input'];
  this.CheckedIdDynamic[index] = data[0]['cat_ques_id'];
  this.CheckedOptionDynamic[index] = data[0]['option'];
  }

  // Display Data Function
  // Filter Data In Next Box in HVAC Profile
  firstDropDownChanged(val: any, id:number) {
    //console.log(val)
     this.IsHidden = false;
     // Filter Data In Next Box API
     this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getFilter?option=" + val)
     .map((response) => response.json() )
     .subscribe((data) => this.filteroption(data))
      let isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent)
      if(isIEOrEdge == true){
        this.IsHiddens = true;
      }else{
        this.IsHiddens = false;
      }
  }
  // Break , in Next option value
  filteroption(data) {
    this.nextoptionfilters = data;
    this.nextoptionfilter = []
    for(var i = 0; i<data.length; i++){
    var input = data[i]['input'].split(',');
    this.nextoptionfilter.push(... input);
    }
  } 
  natural1;
  FuelSource(data){
    this.FuelSources = data;
    this.FuelSourcess = []
    for(var i = 0; i<data.length; i++){
    var option1 = data[i]['option'].split(',');
    this.FuelSourcess.push(... option1);
    this.natural1 = option1.splice(0,1);
    }
    
    
  }
  n12year;
  // break in option values
  displaydataoptionvaluefilter(data) {this.HVACdatafilter = data;
    this.HVACdatafilter = []
    for(var i = 0; i<data.length; i++){
    var option1 = data[i]['option'].split(',');
    this.HVACdatafilter.push(... option1);
    this.n12year = option1.splice(0,1)[0].trim().toLowerCase();
    }
  }
  // Break , in option value
  displaydataoptionvalue(data) {this.optionfilter = data;
    this.optionfilters = []
    for(var i = 0; i<data.length; i++){
    var option = data[i]['option'].split(',')
    this.optionfilters.push(... option)
    }
  } 
  
  //Get Sources For Calculation
  selectvalues:number[] = [];
  selectId:number[] = [];
  selectvaluesquestionId:number[] = [];
  selectvaluesquestionoption:number[] = [];
  selectvaluessourecrate:number[] = [];
  NewCostCooling;
  NaturalCompare;
  heatvalue;
  CoolingComares;
  SelectBoxSource(val: any, index:number) {
    // Filter Data In Next Box API
   this.heatvalue = val
   this.http.get("https://logo.sdssoftltd.co.uk/index.php/calculates/getRates?power_sourse=" + val)
   .map((response) => response.json() )
   .subscribe((data) => this.selectvalue(data, index))
   this.NaturalCompare = (this.heatvalue.trim().toLowerCase())
  // console.log(this.NaturalCompare)
  }

  selectvalue(data, index:number) {
    this.selectId[index] = data[0]['id'];
    this.selectvalues[index] = data[0]['source_rate'];
    this.selectvaluesquestionId[index] = data[0]['cat_ques_id'];
    this.selectvaluesquestionoption[index] = data[0]['power_sourse'];
    let powers = 1;
    let isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if(data[0]['id'] == powers){
        this.IsNatural = false; 
    } else{
      this.IsNatural = true;
    }
    //alert(isIEOrEdge);
    if(isIEOrEdge == true){
      if(data[0]['id'] == powers){
        this.IsNaturals = true;
      } 
    }else{
      if(data[0]['id'] == powers){
        this.IsNaturals = false;
      }else{
        this.IsNaturals = true;
      }
    }
 }

 //Cooling Data
 CoolingDataValue(col: any, index:number) {
  // Filter Data In Next Box API
  this.http.get("https://logo.sdssoftltd.co.uk/index.php/calculates/getEquipments?cooling_equipment=" + col)
  .map((response) => response.json() )
  .subscribe((data) => this.CoolingDataValues(data, index))
  this.CoolingComares = (col.trim().toLowerCase());
 }
 CoolingDataValues(data, index:number) {
    this.selectvalues[index] = data[0]['cooling_efficency_cons'];
    this.selectvaluesquestionId[index] = data[0]['cat_ques_id'];
    this.selectvaluessourecrate[index] = data[0]['source_rate'];
    this.selectvaluesquestionoption[index] = data[0]['cooling_equipment'];
    this.CoolingCostValues = [];
    var CoolingCost: number = 0;
    CoolingCost =  this.bdlgheatinglood * this.CoolCostConstantValue * this.selectvalues[index] * this.selectvaluessourecrate[index];
    this.NewCostCooling = (CoolingCost).toFixed(0)
    this.CoolingCostValue1 = CoolingCost;
    this.CoolingCostValues.push(this.NewCostCooling);
 }

  // All Hvac data
  AllDataValue(valId: any, value: any, index:number) {
    // Filter Data In Next Box API
   this.http.get("https://logo.sdssoftltd.co.uk/index.php/Categories/getInput?ques_id="+valId+"&option="+value )
   .map((response) => response.json() )
   .subscribe((data) => this.AllDataValues(data, index))
  }

  AllDataValues(data, index:number) {
    this.selectvalues[index] = data[0]['input'];
    this.selectvaluesquestionId[index] = data[0]['cat_ques_id'];
    this.selectvaluesquestionoption[index] = data[0]['option'];
  }
  //Hvac All Data Post
  // Equipment in other
  SelectBoxEfficiency(equipment:any, index:number){
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/calculates/getHeating?heating_equipment="+equipment+"&option="+this.heatvalue)
    .map((response) => response.json() )
    .subscribe((data) => this.Efficiency(data, index))
    this.HeatEquips = equipment.trim().toLowerCase();
  }

  // Calculation to heating cost  
  HeatEquips;
  HeatingCostValues;
  HeatingCostValue;
  HeatingCostValue1;
  CoolingCostValue1
  HeatingCostPercentagage;
  CoolingCostValues;
  NewCostHeating : any;
  optionsdata:string;
  optionotherfilter;
  optionsvaluedata;
  Whefficiency:number[] = [];
  Sourcerate:number [] = [];
  WaterCatid:number [] = [];
  Wateroption:number[] = [];
  living;
  WaterHetingCostResult;
  LightingCostResult;
  ApplianceCostResult;
  appliancesvalue;
  WaterHetingCostPercent;
  WaterHetingCost1;
  LightingCostPercent;
  LightingCost1;
  ApplianceCostPercent;
  TotalCost;
  TotalCostResult;
  ApplianceCost1;
  TotalCost1;
  ResultPostPercentage;
  CalculationData;
  CalculatedResult;
  CoolingOptionData;
  NewCostWater: any;
  NewCostLight : any;
  NewCostAppliance : any;
  optionsvaluewater;
  optionsvaluewaters;
  HeatPie;
  Coolpie;
  WaterHeatPie;
  LightHeatPie;
  AppliancePie;
  HeatingComparevalue;
  WaterComparevalue;
  CoolingComparevalue;
  LightingComparevalue;
  AppliancesComparevalue;
  CoolVendor;
  WaterVendor;
  LightVendor;
  AppVendor;
  VendorId;
  coordss1;
  area1;
  screens;
  vendorids;
  vendortitle;
  WaterCompare;
  WaterValuess;
  OtherFees;
  otherfee;
  IsMapNone = true;
  isFirstAttempInsu = true;
 
  Efficiency(data, index:number) {
    this.selectvalues[index] = data[0]['heating_efficiency_cons'];
    this.selectvaluesquestionId[index] = data[0]['cat_ques_id'];
    this.selectvaluessourecrate[index] = data[0]['source_rate'];
    this.selectvaluesquestionoption[index] = data[0]['heating_equipment'];
    this.HeatingCostValues = [];
    // console.log(data[0]['heating_equipment'])
    var HeatingCost: number = 0;
    HeatingCost =  this.bdlgcoolinglood * this.HeatCostConstantValue * this.selectvalues[index] * this.selectvaluessourecrate[index];
    this.NewCostHeating = (HeatingCost).toFixed(0)
    this.HeatingCostValue1 = HeatingCost;
    this.HeatingCostValues.push(this.NewCostHeating); 
  }
  // Declare veriable in calculation purpose
  //Other Data in Option
  displaydataother(data) {this.optionsdata = data;
    this.optionotherfilter = []
    for(var i = 0; i<data.length; i++){
    var option = data[i]['option'].split(',')
    this.optionotherfilter.push(... option)
    } 
  }
  DisplayDataOption(data) {this.optionsvaluedata = data;
    this.optionsvaluedata = []
    for(var i = 0; i<data.length; i++){
    var option = data[i]['option'].split(',')
    this.optionsvaluedata.push(... option)
    } 
  } 
 DisplayDataWater(data){
    this.optionsvaluewater = data;
    this.optionsvaluewaters = []
    for(var i = 0; i<data.length; i++){
    var option = data[i]['option'].split(',')
    this.optionsvaluewaters.push(... option)
    //console.log(this.optionsvaluewaters)
    } 
  }
  older20year;
  t15to24;
  t11to14;
  //Cooling Data
  CoolingData(data) {this.CoolingOptionData = data;
    this.CoolingOptionData = []
    for(var i = 0; i<data.length; i++){
    var option = data[i]['option'].split(',');
    this.CoolingOptionData.push(... option);
    this.older20year = option.splice(0,1)[0].trim().toLowerCase();
    this.t15to24 = option.splice(0,1)[0].trim().toLowerCase();
    this.t11to14 = option.splice(0,1)[0].trim().toLowerCase();
    } 
  } 
 //Appliance Data
  appliances(data) {this.appliancesvalue = data;
    this.appliancesvalue = []
    for(var i = 0; i<data.length; i++){
    var option = data[i]['option'].split(',')
    this.appliancesvalue.push(... option)
    }
  }
 //Send All Hvac Data

 AllWaterChange(valId:any, value: any, index:number){
   // Filter Data In Next Box API
  this.http.get("https://logo.sdssoftltd.co.uk/index.php/Categories/getInput?ques_id="+valId+"&option="+value )
  .map((response) => response.json() )
  .subscribe((data) => this.AllDataothers(data, index))
 }

 AllDataothers(data, index:number) {
  this.Whefficiency[index] = data[0]['input'];
  this.WaterCatid[index] = data[0]['cat_ques_id'];
  this.Wateroption[index] = data[0]['option'];
 }

  //wATER HEATING CONSTANT VALUE
  WaterChange(value: any, index:number) {
    this.WaterValuess = value
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/calculates/getHeaters?water_heater="+value )
    .map((response) => response.json() )
    .subscribe((data) => this.WaterValue(data, index))
    this.WaterCompare = (this.WaterValuess.trim().toLowerCase())
  }

 // wATER HEATING CALCULATION
  WaterValue(data, index:number) {
    this.Whefficiency[index] = data[0]['w.h efficiency cons.'];
    this.Sourcerate[index] = data[0]['source_rate'];
    this.WaterCatid[index] = data[0]['cat_ques_id'];
    this.Wateroption[index] = data[0]['water_heater'];
    this.WaterHetingCostResult = [];
    this.HeatingCostValue = [];
    var WaterHetingCost: number = 0;
    WaterHetingCost = (((12*this.livingValue/1000)+44)* this.Whefficiency[index] * this.Sourcerate[index]);
    this.WaterHetingCost1 = WaterHetingCost;
    this.NewCostWater = (WaterHetingCost).toFixed(0);
    this.WaterHetingCostResult.push(this.NewCostWater);
    //console.log(WaterHetingCost);
  }
  //lIGHTING DATA POST AND CALCULATION
  LightingChange(value: any, index:number) {
    // Filter Data In Next Box API
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/calculates/getLightings?lighting="+value )
    .map((response) => response.json() )
    .subscribe((data) => this.LightingValue(data, index))
  }

  LightingValue(data, index:number) {
    this.Whefficiency[index] = data[0]['efficiency cons.'];
    this.Sourcerate[index] = data[0]['source_rate'];
    this.WaterCatid[index] = data[0]['cat_ques_id'];
    this.Wateroption[index] = data[0]['lighting'];
    this.LightingCostResult = [];
    var LightingCost: number = 0;
    LightingCost = ((90*this.livingValue/1000)+70)* this.Whefficiency[index] * this.Sourcerate[index];
    this.LightingCost1 = LightingCost;
    this.NewCostLight = (LightingCost).toFixed(0);
    this.LightingCostResult.push(this.NewCostLight);
  }

  //APPLIANCE DATA POST AND CALCULATION
  ApplianceChange(value: any, index:number) {
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/calculates/getAppliances?appliances="+value )
    .map((response) => response.json())
    .subscribe((data) => this.ApplianceValue(data, index));
  }
 ApplianceValue(data, index:number) {
    this.Whefficiency[index] = data[0]['appliances_factor'];
    this.Sourcerate[index] = data[0]['source_rate'];
    this.WaterCatid[index] = data[0]['cat_ques_id'];
    this.Wateroption[index] = data[0]['appliances_type'];
    this.ApplianceCostResult = [];
    this.HeatingCostValue = [];
    this.TotalCostResult = [];
    this.HeatingCostPercentagage = [];
    var ApplianceCost: number = 0;
    ApplianceCost = (((210*this.livingValue/1000)+190)* this.Whefficiency[index] * this.Sourcerate[index]);
    this.ApplianceCost1 = ApplianceCost;
    this.NewCostAppliance = (ApplianceCost).toFixed(0);
    this.ApplianceCostResult.push(this.NewCostAppliance);
    // Post and Result Data
    this.HeatingCostValue.push(this.HeatingCostValues);
    this.HeatingCostValue.push(this.CoolingCostValues);
    this.HeatingCostValue.push(this.WaterHetingCostResult);
    this.HeatingCostValue.push(this.LightingCostResult);
    this.HeatingCostValue.push(this.ApplianceCostResult);
    var TotalCost: number = 0;
    var OtherFees: number = 0;
    TotalCost = (this.HeatingCostValue1 + this.CoolingCostValue1 + this.WaterHetingCost1 + this.LightingCost1 + this.ApplianceCost1);  
    this.TotalCost1 = TotalCost;
    this.TotalCostResult.push(TotalCost);
    //PERCENTAGE COUNT Data
    var HeatingCostPercentage = (((this.HeatingCostValue1*100)/this.TotalCost1).toFixed(0));
    this.HeatingCostPercentagage.push(HeatingCostPercentage);
    // this.HeatPie = HeatingCostPercentage;

    var WaterCoolingCostPercentage = (((this.CoolingCostValue1*100)/this.TotalCost1).toFixed(0));
    this.HeatingCostPercentagage.push(WaterCoolingCostPercentage);
    //this.Coolpie = WaterCoolingCostPercentage;

    var WaterHeatingCostPercentage = (((this.WaterHetingCost1*100)/this.TotalCost1).toFixed(0));
    this.HeatingCostPercentagage.push(WaterHeatingCostPercentage);
    // this.WaterHeatPie = WaterHeatingCostPercentage;

    var LightHeatingCostPercentagage = (((this.LightingCost1*100)/this.TotalCost1).toFixed(0))
    this.HeatingCostPercentagage.push(LightHeatingCostPercentagage);
    //this.LightHeatPie = LightHeatingCostPercentagage;

    var  ApplianceHeatingCostPercentagage = (((this.ApplianceCost1*100)/this.TotalCost1).toFixed(0))
    this.HeatingCostPercentagage.push(ApplianceHeatingCostPercentagage);
    //this.AppliancePie = ApplianceHeatingCostPercentagage;
    
    OtherFees = (this.WaterHetingCost1 + this.LightingCost1 + this.ApplianceCost1); 
    this.otherfee = OtherFees; 
 }

  // First Data Send
  SendQuestionData():void{
    var requestMessage = {
     "cat_ques_id" : this.checkedvaluesid,
     "cust_input" : this.checkedvalues,
     "cat_ques_option" : this.checkedvalueInput,
     "post_code" : this.Pstalcodes,
     "living_area" : this.livingValue,
    }
    if(this.isFirstAttempInsu){
     // console.log(JSON.stringify(requestMessage));
     this.http.post("https://logo.sdssoftltd.co.uk/index.php/categories/postInput?post_code="+this.Pstalcodes+"&living_area="+this.livingValue+"&cat_ques_id="+ this.checkedvaluesid+"&cat_ques_option="+this.checkedvalueInput+"&cust_input="+this.checkedvalues,"")
      .map((response) => response.json())
      .subscribe();
      this.isFirstAttempInsu = false;
    }
  }

  isFirstAttempsDoor = true;
  SsndQuestionData2():void{
    var requestMessage = {
      "cat_ques_id" : this.checkedvaluesdoorid,
      "cust_input" : this.checkedvaluesdoor,
      "cat_ques_option" : this.checkedvaluesdoorInput,
      "post_code" : this.Pstalcodes,
      "living_area" : this.livingValue,
    }
    if(this.isFirstAttempsDoor){
      //console.log(JSON.stringify(requestMessage));
      this.http.post("https://logo.sdssoftltd.co.uk/index.php/categories/postInput?post_code="+this.Pstalcodes+"&living_area="+this.livingValue+"&cat_ques_id="+ this.checkedvaluesdoorid+"&cat_ques_option="+this.checkedvaluesdoorInput+"&cust_input="+this.checkedvaluesdoor,"")
      .map((response) => response.json())
      .subscribe();
      this.isFirstAttempsDoor = false;
    }
  }

  isFirstAttempHvac = true;
  SsndQuestionData3():void{
    var requestMessage = {
      "cat_ques_id" : this.selectvaluesquestionId,
      "cust_input" : this.selectvalues,
      "&cat_ques_option" : this.selectvaluesquestionoption,
      "post_code" : this.Pstalcodes,
      "living_area" : this.livingValue,
    }
    if(this.isFirstAttempHvac){
      this.http.post("https://logo.sdssoftltd.co.uk/index.php/categories/postInput?post_code="+this.Pstalcodes+"&living_area="+this.livingValue+"&cat_ques_id="+ this.selectvaluesquestionId+"&cat_ques_option="+this.selectvaluesquestionoption+"&cust_input="+this.selectvalues,"")
      .map((response) => response.json())
      .subscribe();
      this.isFirstAttempHvac = false;
   }
 }

  isFirstAttempOther = true;
  SsndQuestionData4():void{
    var requestMessage = {
      "cat_ques_id" : this.WaterCatid,
      "cust_input" : this.Whefficiency,
      "cat_ques_option" : this.Wateroption,
      "post_code" : this.Pstalcodes,
      "living_area" : this.livingValue,
    }
    if(this.isFirstAttempOther){
      //console.log(JSON.stringify(requestMessage));
      this.http.post("https://logo.sdssoftltd.co.uk/index.php/categories/postInput?post_code="+this.Pstalcodes+"&living_area="+this.livingValue+"&cat_ques_id="+ this.WaterCatid+"&cat_ques_option="+this.Wateroption+"&cust_input="+this.Whefficiency,"")
      .map((response) => response.json())
      .subscribe();
      //  .subscribe((data) => console.log(data))
      this.isFirstAttempOther = false;
    }
          
  }
  InsulationRetrofit;
  ReplaceWindow;
  ReplaceFurnace;
  ReplaceAirc;
  GeoThermalHeat;
  SolarHeatInstall;
  SolarPvInstall;
  isFirstAttempHvacsolution = true;
 SendDynamicDataQuestionResult(){

    // Condition to vendor
      // Other Vendors
      this.NaturalGas = this.natural1[0].trim().toLowerCase();
      this.NaturalComapres= this.n12year;
      this.coolingolder = this.older20year;
      this.cooling15 = this.t15to24;
      this.cooling11 = this.t11to14;
      // Insulation Condition
      if(this.checkedvalueInput[0] == this.checkedvalueInput[1] && this.checkedvalueInput[0] == this.checkedvalueInput[2] && this.checkedvalueInput[1] == this.checkedvalueInput[2]){
          this.InsRetrofit1 = false;  
          this.InsulationRetrofit  = "No"
      }else{
        this.InsRetrofit = false;
        this.InsulationRetrofit  = "Yes"
      } 
      // Window And Doors Condition
      if(this.checkedvaluesdoorInput[0] == this.checkedvaluesdoorInput[1]){
        this.Rwindows= false;
        this.ReplaceWindow  = "Yes"
      }else{
        this.Rwindows1= false;
        this.ReplaceWindow  = "No"
      }

      //Other 
      if(this.NaturalGas == this.NaturalCompare && this.NaturalComapres == this.HeatEquips){
        this.RFurnesh = false;
    
        this.ReplaceFurnace  = "Yes"
      } else{
        this.RFurnesh1 = false;
        this.ReplaceFurnace  = "No"
      }
      //Other
      if(this.CoolingComares == this.coolingolder || this.CoolingComares == this.cooling15 || this.CoolingComares == this.cooling11){ 
        this.RAir = false;
        this.ReplaceAirc  = "Yes"
      }else{
        this.RAir1 = false;
        this.ReplaceAirc  = "No"
      }
      // GeoThermal HeatPump Consdition
      if(this.NaturalGas == this.NaturalCompare){
        this.GHeat1 = false;
        this.GeoThermalHeat  = "No"
        }else{
        this.GHeat = false;
        this.GeoThermalHeat  = "Yes"
      }

      if(this.otherfee >=1500 || this.NaturalGas != this.NaturalCompare){
        this.SPV = false;
        this.SolarPvInstall = "Yes"
      }else{
        this.SPV1 = false;
        this.SolarPvInstall = "No"
      }
      // install solar heat collector 
      if(this.WaterHetingCost1 >= 500 || this.NaturalGas != this.WaterCompare){
      this.ISHC =false;
      this.SolarHeatInstall = "Yes"
      }else{
        this.ISHC1 =false; 
        this.SolarHeatInstall = "No"
      }
   
        var requestMessage = {
          "insulation" : this.InsulationRetrofit,
          "window" : this.ReplaceWindow,
          "furnace" : this.ReplaceFurnace,
          "air_conditioner" : this.ReplaceAirc,
          "geothermal" : this.GeoThermalHeat,
          "solar_pv" : this.SolarPvInstall,
          "heat_collector" : this.SolarHeatInstall
        }
        setTimeout(() => {
        if(this.isFirstAttempHvacsolution){
        this.http.post("https://logo.sdssoftltd.co.uk/index.php/vendors/mainSolution?insulation="+this.InsulationRetrofit+"&window="+this.ReplaceWindow+"&furnace="+this.ReplaceFurnace+"&air_conditioner="+this.ReplaceAirc+"&geothermal="+this.GeoThermalHeat+"&solar_pv="+this.SolarPvInstall+"&heat_collector="+this.SolarHeatInstall,"")
        .map((response) => response.json())
        .subscribe((data) => console.log(data))
        this.isFirstAttempHvacsolution = false;
        }
      }, 1000);
      //Function
      this.SendDynamicDataQuestion();
      this.SendResulatAll();
  } 
  SendDynamicDataQuestion():void{
    var requestMessage = {
      "cat_ques_id" : this.CheckedIdDynamic,
      "cust_input" : this.CheckedInputDynamic,
      "cat_ques_option" : this.CheckedOptionDynamic,
      "post_code" : this.Pstalcodes,
      "living_area" : this.livingValue,
    }
    // console.log(JSON.stringify(requestMessage));
    this.http.post("https://logo.sdssoftltd.co.uk/index.php/categories/postInput?post_code="+this.Pstalcodes+"&living_area="+this.livingValue+"&cat_ques_id="+ this.CheckedIdDynamic+"&cat_ques_option="+this.CheckedOptionDynamic+"&cust_input="+this.CheckedOptionDynamic,"")
    .map((response) => response.json())
    .subscribe()
  }

  isFirstAttempdata = true; 
  SendResulatAll():void{
    var requestMessage = {
      "&current_cost" : this.HeatingCostValue,
      "&percentage" : this.HeatingCostPercentagage,
    }
    if(this.isFirstAttempdata){
      this.http.post("https://logo.sdssoftltd.co.uk/index.php/calculates/postOutput?calculated_cost=Heating Cost, Cooling Cost, Water Heating Cost, Lighting Cost, Appliances Cost&current_cost="+this.HeatingCostValue+"&percentage="+this.HeatingCostPercentagage,"")
        .map((response) => response.json())
        .subscribe();
        // .subscribe((data) => console.log(data))
        this.isFirstAttempdata = false;
    }
  }

  NaturalGas;
  InsRetrofit = true;
  InsRetrofit1 = true;
  Rwindows= true;
  Rwindows1= true;
  RFurnesh = true;
  RFurnesh1 = true;
  RAir = true;
  RAir1 = true;
  GHeat = true;
  GHeat1 = true;
  SPV = true;
  SPV1  = true;
  ISHC1 = true;
  ISHC =  true;
  HiddenRetro = true;
  HiddenRetro1 = true;
  HiddenRetro2 = true;
  HiddenRetro3 = true;
  HiddenRetro4 = true;
  HiddenRetro5 = true;
  HiddenRetro6 = true;
  HiddenRetro7 = true;
  HiddenRetro8 = true;
  HiddenRetro9 = true;
  HiddenRetro10 = true;
  HiddenRetro11 = true;
  HiddenRetro12 = true;
  LightVendorOther;
  AppVendorOther;
  WaterHeatOther;
  CoolingVendorother;
  InsulationOption;
  NaturalComapres;
  HeatVenOther;
  coolingolder;
  cooling15;
  cooling11;

   addHero(valss: any, cords:any, screen:any){
    //console.log(atob("screen"))
    this.area1 = valss;
    this.coordss1 = cords;
    this.screens = screen;

    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getSolutions?solution=Insulation retrofit")
    .map((response) => response.json())
    .subscribe((data) => this.Retrofit(data))
  
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getSolutions?solution=Replace windows and/or doors")
    .map((response) => response.json())
    .subscribe((data) => this.Replace(data))
  
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getSolutions?solution=Replace Furnace")
    .map((response) => response.json())
    .subscribe((data) => this.Furnace(data))
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getSolutions?solution=Install solar PV")
    .map((response) => response.json())
    .subscribe((data) => this.PV(data))
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getSolutions?solution=Replace Air conditioner")
    .map((response) => response.json())
    .subscribe((data) => this.conditioner(data))
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getSolutions?solution=Geothermal heatpump")
    .map((response) => response.json())
    .subscribe((data) => this.Geothermal(data))
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getSolutions?solution=Install solar heat collector")
    .map((response) => response.json())
    .subscribe((data) => this.collector(data))
  
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getSolutions?solution=Lights Improvement")
    .map((response) => response.json())
    .subscribe((data) => this.Improvement(data))
  
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getSolutions?solution=Appliances")
    .map((response) => response.json())
    .subscribe((data) => this.Appliancesss(data))
  
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getSolutions?solution=Smart Vents")
    .map((response) => response.json())
    .subscribe((data) => this.Vents(data))
  
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getSolutions?solution=Smart Thermostat")
    .map((response) => response.json())
    .subscribe((data) => this.Thermostat(data))
  
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getSolutions?solution=Air Seals")
    .map((response) => response.json())
    .subscribe((data) => this.Seals(data))
   
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/categories/getSolutions?solution=Attic Vent")
    .map((response) => response.json())
    .subscribe((data) => this.Attic(data))

  }
   
    
  Name: string = "";
  Email: string = "";
  Phone: string = "";
  SendDetails():void{
    var requestMessage = {
      "name" : this.Name,
      "email" : this.Email,
      "mobile" : this.Phone,
      "address" : this.ShowAddresss,
      "area" : this.area1,
      "coordinate" : this.coordss1,
      "screenshot" : this.screens
    }
    this.http.post("https://logo.sdssoftltd.co.uk/index.php/users/postDetail?name="+this.Name+"&email="+this.Email+"&mobile="+this.Phone+"&address="+this.ShowAddresss+"&area=" +this.area1+"&coordinate="+this.coordss1+"&screenshot="+this.screens,"")
    .map((response) => response.json())
    //.subscribe((data) => console.log(data))
    .subscribe(data => {}, error => {alert("Your data has been failed, Please try again");})
    this.Name = "";
    this.Email = "";
    this.Phone = "";
    this.ShowAddresss = "";
    this.area1 = "";
    this.coordss1 = "";
    this.screens="";
   
    // send Userdata in mail
    setTimeout(() => {
      this.http.get("https://logo.sdssoftltd.co.uk/index.php/users/userEmail")
      .map((response) => response.json())
      //.subscribe((data) => console.log(data))
      .subscribe()
    }, 1500);
    
  }
    
  CategoryStatic(categoryDatastatic){this.CategoryStaticData = categoryDatastatic;}  
  Furnace(data){this.Furnaces = data;}
  conditioner(data){this.conditioners = data;}
  Geothermal(data){this.Geothermals = data;}
  PV(data){this.PVs = data;}
  collector(data){this.collectors = data;}
  Vents(data){this.Ventss = data;}
  Thermostat(data){this.Thermostats = data;}
  Seals(data){this.Sealss = data;}
  Attic(data){this.Attics = data;}
  Improvement(data){this.Improvements = data;}
  Appliancesss(data){this.Appliancessss = data;}
  Retrofit(data){this.IRetrofit = data;}
  Replace(data){this.Replaces = data;}
  vendorname;
  VendorMail(value:any, vtitle:any, vname:any):void{
	  this.vendorids = value;
    this.vendortitle = vtitle;
    this.vendorname = vname;
	 this.http.post("https://logo.sdssoftltd.co.uk/index.php/users/vendorEmail?id="+this.vendorids +"&p_title="+this.vendortitle,"")
    .map((response) => response.json())
    //.subscribe((data) => console.log(data))
    .subscribe(data => {alert("Your contact details have been mailed to " + this.vendorname);}, error => {alert("Vendor details not available, Please select another vendor");});
    document.getElementById(this.vendorids).setAttribute('disabled', 'disabled');
  }
  RetroFit(){
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/vendors/solutionVendors?solution=insulation retrofit&lat="+this.lat+"&long="+this.lng,"")
    .map((response) => response.json())
    .subscribe((data) => this.insulationVendor(data))

    this.HiddenRetro = false;
  }
  RetroFit1(){
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/vendors/solutionVendors?solution=Replace windows and/or doors&lat="+this.lat+"&long="+this.lng,"")
    .map((response) => response.json())
    .subscribe((data) => this.ReplaceVendor(data)) 

      this.HiddenRetro1 = false;
    }
  RetroFit2(){ 
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/vendors/solutionVendors?solution=Replace Furnace&lat="+this.lat+"&long="+this.lng,"")
    .map((response) => response.json())
    .subscribe((data) => this.furnacevendor(data)) 
    this.HiddenRetro2 = false;
    }
  RetroFit3(){
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/vendors/solutionVendors?solution=Replace Air conditioner&lat="+this.lat+"&long="+this.lng,"")
    .map((response) => response.json())
    .subscribe((data) => this.Airvendor(data))
    this.HiddenRetro3 = false;
    }
  RetroFit4(){
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/vendors/solutionVendors?solution=Geothermal heatpump&lat="+this.lat+"&long="+this.lng,"")
    .map((response) => response.json())
    .subscribe((data) => this.GeothermalVendor(data))

    this.HiddenRetro4 = false;
    }
  RetroFit5(){
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/vendors/solutionVendors?solution=Install solar PV&lat="+this.lat+"&long="+this.lng,"")
    .map((response) => response.json())
    .subscribe((data) => this.SolarVendor(data))

    this.HiddenRetro5 = false;
    }
 RetroFit6(){ 
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/vendors/solutionVendors?solution=Install solar heat collector&lat="+this.lat+"&long="+this.lng,"")
    .map((response) => response.json())
    .subscribe((data) => this.HeatVendors(data))
    this.HiddenRetro6 = false;
  }
 RetroFit7(){
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/vendors/solutionVendors?solution=Lights Improvement&lat="+this.lat+"&long="+this.lng,"")
    .map((response) => response.json())
    .subscribe((data) => this.LightVendors(data))
    this.HiddenRetro7 = false;
  }
 RetroFit8(){
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/vendors/solutionVendors?solution=Appliances&lat="+this.lat+"&long="+this.lng,"")
    .map((response) => response.json())
    .subscribe((data) => this.AppliancesVendors(data))
      this.HiddenRetro8 = false;
 }
 RetroFit9(){
   this.http.get("https://logo.sdssoftltd.co.uk/index.php/vendors/solutionVendors?solution=Smart Vents&lat="+this.lat+"&long="+this.lng,"")
   .map((response) => response.json())
   .subscribe((data) => this.SmartVendors(data))
   this.HiddenRetro9 = false;
  }
 RetroFit10(){ 
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/vendors/solutionVendors?solution=Smart Thermostat&lat="+this.lat+"&long="+this.lng,"")
    .map((response) => response.json())
    .subscribe((data) => this.ThermostatVendors(data))
    this.HiddenRetro10 = false;
 }
 RetroFit11(){
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/vendors/solutionVendors?solution=Air Seals&lat="+this.lat+"&long="+this.lng,"")
    .map((response) => response.json())
    .subscribe((data) => this.SealsVendors(data))
    this.HiddenRetro11 = false;
   }
 RetroFit12(){
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/vendors/solutionVendors?solution=Attic Vent&lat="+this.lat+"&long="+this.lng,"")
    .map((response) => response.json())
    .subscribe((data) => this.VentVendors(data))
    this.HiddenRetro12 = false;
 }
 
  public scroll(el:any) {
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 2000);
  }
 
   ///main Solution
   insulationVendor(data) {this.HeatVendor = data;}
   ReplaceVendor(data) {this.CoolVendor = data;}
   furnacevendor(data) {this.WaterVendor = data;}
   Airvendor(data) {this.LightVendor = data;}
   GeothermalVendor(data) {this.AppVendor = data;}
   SolarVendor(data){this.HeatVenOther = data;}
   HeatVendors(data){this.CoolingVendorother = data;}
   LightVendors(data){this.WaterHeatOther = data;}
   AppliancesVendors(data) {this.LightVendorOther = data;}
   SmartVendors(data) {this.AppVendorOther = data;}
   ThermostatVendors(data) {this.ThermostatVendors1 = data;}
   SealsVendors(data) {this.SealsVendors1 = data; }
   VentVendors(data) {this.VentVendors1 = data;}  
   HeatVendor ;
   Rate;
   review: string = "";
   RatingSubmit(value:any):void{
    this.Rate = value;
    var requestMessage = {
      "name" : this.review
    }
   
  
    this.http.post("https://logo.sdssoftltd.co.uk/index.php/users/postRating?rating="+this.Rate+"&review="+this.review,"")
    .map((response) => response.json())
    .subscribe(data => {
        let dialogRef = this.dialog.open(VedioComponent, { disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
        if (result == 'confirm') {
            console.log('Unregistered');
        }})
    }, error => {   
        let dialogRef = this.dialog.open(ErrorComponent, { disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
        if (result == 'confirm') {
            console.log('Unregistered');
        }})
    });
    setTimeout(() => {
      this.cookieService.deleteAll();
    }, 500);
   }
  
   Cokkie(){
     this.cookieService.deleteAll();
   }
   windowload(){
     this.cookieService.deleteAll();
      setTimeout(() => {
        location.reload();
      }, 500);
    }
    ngAfterViewInit() {
      this.cookieService.deleteAll();
    }

}

// just an interface for type safety.
interface marker {
	lat: number;
	lng: number;
}