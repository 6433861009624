import { QuestionData } from "./QuestionData";

export class CategoryQuestionsData {

    categoryName:string;
    questions:QuestionData[];

    public setCategoryName(categoryName:string) {
        this.categoryName = categoryName   
    }

    public setQuestions(questions:QuestionData[]) {
        this.questions = questions
    }
}
