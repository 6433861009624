
export class QuestionData {

    question:string;
    id:string;
    options:string[];

    public setQuestion(question:string) {
        this.question = question
    }
    public setQuestionId(id:string) {
        this.id = id
    }

    public setQuestionsOptions(options:string[]) {
        this.options = options
    }
    
}
