import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-alert-dialog',
  templateUrl: './my-alert-dialog.component.html',
  styleUrls: ['./my-alert-dialog.component.css']
})
export class MyAlertDialogComponent implements OnInit {

  constructor() { }
  Reload(){
    setTimeout(() => {
     location.reload();
    },500)
  }
  ngOnInit() {
  }

}
