import { NgModule }       from '@angular/core';
import { BrowserModule , Title }  from '@angular/platform-browser';
import { FormsModule }    from '@angular/forms';
import { HttpClientModule}    from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatInputModule,
  MatAutocompleteModule,
  MatDialogModule,
  MatToolbarModule
 } from '@angular/material';
 import { RatingModule } from 'ngx-bootstrap';
import {HttpModule} from '@angular/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { AgmCoreModule } from '@agm/core';
//import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap';
import { AppRoutingModule }     from './app-routing.module';
import { AppComponent }         from './app.component';
import { MapComponent } from './map/map.component';
import { IndexAboutComponent } from './index-about/index-about.component';
import { FooterComponent } from './footer/footer.component';
import { IndexComponent } from './index/index.component';
import { AboutComponent } from './about/about.component';
import { AboutHeadingComponent } from './about-heading/about-heading.component';
import { ContactComponent } from './contact/contact.component';
import { ContactHeadingComponent } from './contact-heading/contact-heading.component';
import { HelpComponent } from './help/help.component';
import { HelpHeadingComponent } from './help-heading/help-heading.component';
import { TermsComponent } from './terms/terms.component';
import { TermsHeadingComponent } from './terms-heading/terms-heading.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PrivacyHeadingComponent } from './privacy-heading/privacy-heading.component';
import { LoginComponent } from './login/login.component';
import { CommonModule } from '@angular/common';
import { SwiperModule } from './swiper/swiper.module';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { VedioComponent } from './vedio/vedio.component';
import { MyAlertDialogComponent } from './my-alert-dialog/my-alert-dialog.component';
import { ErrorComponent } from './error/error.component';

@NgModule({
 
  imports: [
      RatingModule,
      NgxPaginationModule,
      MatToolbarModule,
      MatDialogModule,
      CommonModule,
      SwiperModule,
      MatButtonModule,
      MatCardModule,
      BrowserModule,
      FormsModule,
      AppRoutingModule,
      ModalModule.forRoot(),
      CollapseModule.forRoot(),
      HttpClientModule,
      MatAutocompleteModule,
      TooltipModule.forRoot(),
      ScrollToModule.forRoot(),
      //HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, { dataEncapsulation: false }),
      BrowserAnimationsModule,
      MatButtonModule,
      MatInputModule,
      MatCheckboxModule,
      HttpModule,
      AgmCoreModule.forRoot({
        // please get your own API key here:
        // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
        apiKey: 'AIzaSyCGwOb36FPLYBlvhMcVcwJq4mIOzTUpSNo'
      })
  ],
  entryComponents: [MyAlertDialogComponent, VedioComponent,ErrorComponent],
  declarations: [
    AppComponent,
	  MapComponent,
    IndexAboutComponent,
    FooterComponent,
    IndexComponent,
    AboutComponent,
    AboutHeadingComponent,
    ContactComponent,
    ContactHeadingComponent,
    HelpComponent,
    HelpHeadingComponent,
    TermsComponent,
    TermsHeadingComponent,
    PrivacyComponent,
    PrivacyHeadingComponent,
    LoginComponent,
    VedioComponent,
    MyAlertDialogComponent,
    ErrorComponent,
    
  ],
   providers: [
    Title,
    CookieService 
   
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
