import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-vedio',
  templateUrl: './vedio.component.html',
  styleUrls: ['./vedio.component.css']
})
export class VedioComponent implements OnInit {

  constructor( private cookieService: CookieService) { }
  Reload(){
    this.cookieService.deleteAll();
    setTimeout(() => {
     location.reload();
    },500)
  }
  ngOnInit() {
  }

}
