import { Component, OnInit } from '@angular/core';
import {Http, Response} from '@angular/http';

@Component({
  selector: 'app-privacy-heading',
  templateUrl: './privacy-heading.component.html',
  styleUrls: ['./privacy-heading.component.css']
})
export class PrivacyHeadingComponent implements OnInit {

  TermContents;
  constructor(private http: Http) { }

  ngOnInit() { 
    
    //Postal Code Filter APi
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/contents/getContent?title=Privacy Policy")
    .map((response) => response.json() )
    .subscribe((data) => this.TermContent(data))

  }

  TermContent(data) {this.TermContents = data } 
}
