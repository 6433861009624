import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-heading',
  templateUrl: './help-heading.component.html',
  styleUrls: ['./help-heading.component.css']
})
export class HelpHeadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
