import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  constructor( private cookieService: CookieService) { }
  Reload(){
    this.cookieService.deleteAll();
    setTimeout(() => {
     location.reload();
    },500)
  }
  ngOnInit() {
  }

}
