import { Component, OnInit } from '@angular/core';
import {Http, Response} from '@angular/http';

@Component({
  selector: 'app-contact-heading',
  templateUrl: './contact-heading.component.html',
  styleUrls: ['./contact-heading.component.css']
})
export class ContactHeadingComponent implements OnInit {

     // Only Numbers
     numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
  constructor(private http: Http) { }

  ngOnInit() {
  }

  Name: string = "";
  Email: string = "";
  Number: string = "";
  Message: string = "";
  ContactDetails():void{
    var requestMessage = {
      "name" : this.Name,
      "email" : this.Email,
      "mobile" : this.Number,
      "message" : this.Message,
    
    }
    this.http.post("https://logo.sdssoftltd.co.uk/index.php/users/contactUs?name="+this.Name+"&email="+this.Email+"&mobile="+this.Number+"&message="+this.Message,"")
    .map((response) => response.json())
    .subscribe(data => {alert('Thank you for contacting us , We will get back to you soon.');}, error => {alert("Oops, Please try again");});
    this.Name = "";
    this.Email = "";
    this.Number = "";
    this.Message = "";
   
  }

}
