import { Component, OnInit } from '@angular/core';
import {Http, Response} from '@angular/http';

@Component({
  selector: 'app-index-about',
  templateUrl: './index-about.component.html',
  styleUrls: ['./index-about.component.css']
})
export class IndexAboutComponent implements OnInit {

  AboutContents;
  constructor(private http: Http) { }

  ngOnInit() { 
    
    //Postal Code Filter APi
    this.http.get("https://logo.sdssoftltd.co.uk/index.php/contents/getContent?title=About Us")
    .map((response) => response.json() )
    .subscribe((data) => this.AboutContent(data))

  }

  AboutContent(data) {this.AboutContents = data } 

}
