import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Title }     from '@angular/platform-browser';
import { Http } from '@angular/http';
import {Jsonp} from '@angular/http';
import 'rxjs/add/operator/map';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  isCollapsed = true;
  

  constructor(private titleService: Title, private http: Http) {}
 

  ngOnInit() {}
 
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
 


}